import { createSlice } from "@reduxjs/toolkit"
import { tokenService } from "src/services/token.service"
const isToken = tokenService.getLocalAccessToken()
const initialState: AppState = {
  isAuthenticated: !!isToken ? true : false
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    logout: state => {
      localStorage.clear()
      state.isAuthenticated = false
    },
    loginSuccess: state => {
      state.isAuthenticated = true
    }
  }
})
export const { logout, loginSuccess } = appSlice.actions
export default appSlice.reducer
