import React from "react"
import ReactDOM from "react-dom"
import App from "./App/App"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import { store } from "./store/store"
import { COLOR } from "./utils/constants/styles.constant"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { BrowserRouter as Router } from "react-router-dom"
import NotFound from "./pages/Home/NotFound/NotFound"


Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY
    ? process.env.REACT_APP_BUGSNAG_API_KEY
    : "",
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION || "",
  logger: null
})
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

const themeOptions = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: COLOR.PRIMARY
    },
    secondary: {
      main: COLOR.SECONDARY
    }
  }
})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ErrorBoundary FallbackComponent={NotFound}>
          <ThemeProvider theme={themeOptions}>
            <App />
          </ThemeProvider>
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
