export const PATH = {
  HOME: "/",
  LOGIN: "/login",
  AUTH: "/auth",
  BILLING: "/billing",
  SUBSCRIPTION: "/subscription",
  MULTIPLE_BILLING: "/multiple-billing",
  ADMINISTRATORS: "/admin",
  SUPPORT: "/support",
  PROFILE: "/myprofile",
  DASHBOARD: "/dashboard",
  CREATE_STANDUP: "/standup/new",
  MANAGE_STANDUP: "/standup/manage",
  EDIT_STANDUP: "/standup/:standupId/manage",
  OVERVIEW_STANDUP: "/standup/:standupId/overview",
  ALICE_STANDUP: "/alice/standup/:standupId",
  STANDUP: "/standup/{0}/manage",
  STANDUP_QUESTION: "/standup/{0}/manage/question",
  STANDUP_SCHEDULE: "/standup/{0}/manage/schedule",
  STANDUP_ADVANCED: "/standup/{0}/manage/advanced",
  STANDUP_CREATE: "/standup/manage",
  STANDUP_QUESTION_CREATE: "/standup/manage/question",
  STANDUP_SCHEDULE_CREATE: "/standup/manage/schedule",
  STANDUP_ADVANCED_CREATE: "/standup/manage/advanced",
  OVERVIEW: "/standup/{0}/overview",
  NOT_FOUND: "/404",
  LOGIN_BY_TOKEN: "/teams"
}
