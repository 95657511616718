import { API } from "src/utils/constants/api.constants"
import { UserStatus } from "src/utils/enums/user-status.enum"
import { api } from "../auth.interceptor"

class UserService {
  getUser = (): Promise<Res<MeRes>> => {
    const apiUrl = API.user.me
    return api.get(apiUrl)
  }
  getUsers = (channelId?: string): Promise<Res<PlatformUserRes[]>> => {
    let apiUrl = API.platform.listUsers
    if (channelId) {
      apiUrl += `?channelId=${channelId}`
    }
    return api.get(apiUrl)
  }
  getUsersShared = (userId: string[]): Promise<Res<PlatformUserRes[]>> => {
    const apiUrl = API.platform.sharedUsers
    return api.post(apiUrl, userId)
  }
  updateMyProfile = (user: PersonDto): Promise<Res<PersonDto>> => {
    const apiUrl = API.user.me
    return api.put(apiUrl, user)
  }

  getStandupSettings = (): Promise<Res<StandupSettings[]>> => {
    const url = API.user.settings
    return api.get(url)
  }

  putStandupSettings(settings: StandupSettings[]): Promise<Res<boolean>> {
    const url = API.user.settings
    return api.put(url, settings)
  }

  updateStatus = (state: UserStatus): Promise<Res<any>> => {
    const url = API.user.update_status
    return api.put(url, { state: state })
  }
}
export const userService = new UserService()
