import { combineReducers } from "redux"
import appReducer from "./app.reducer"
import authReducer from "./auth.reducer"
import authToastReducer from "./toastAuth.reducer"
import homeReducer from "./home.reducer"
import userReducer from "./user.reducer"
import standupReducer from "./standup.reducer"
import newStandupReducer from "./new-standup.reducer"
import manageStandupReducer from "./manage.reducer"

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  authToast: authToastReducer,
  home: homeReducer,
  user: userReducer,
  standup: standupReducer,
  newStandup: newStandupReducer,
  manageStandup: manageStandupReducer
})

export default rootReducer
