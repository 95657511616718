import { createToast } from "src/reducer/toastAuth.reducer"
import { messages } from "../constants/messages"

class ToastHelpers {
  public handleToast = (dispatch, message, severity) => {
    if (!message) message = messages.info.unknown_error
    const toast: ToastParams = {
      messages: message,
      severity
    }
    dispatch(createToast(toast))
  }
}

export const toastHelpers = new ToastHelpers()
