import { ERROR_CODE_MESSAGE } from "src/utils/constants/error-code"

class ErrorService {
  handleServerError = (response: Res<any>): any => {
    const { errorCode } = response.data
    if (errorCode) {
      return ERROR_CODE_MESSAGE[errorCode]
    } else {
      return ""
    }
  }
}

export const errorService = new ErrorService()
