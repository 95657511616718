export const API = {
  AUTH: {
    INSTALL: "auth/install",
    LOGIN: "auth/login",
    SIGN_OUT: "auth/logout",
    REFRESH_TOKEN: "auth/refreshToken",
    LOGIN_BY_TOKEN: "auth/onetime?token={0}",
    UPDATE_PAYMENT_LOGIN: "auth/updatePaymentLogin?token={0}"
  },
  user: {
    me: "me",
    settings: "me/settings",
    update_status: "me/status"
  },
  org: {
    base: "/org",
    downloadata: "org/download-data",
    users: "org/users",
    add_admin: "org/add-admin",
    remove_admin: "org/add-admin/{0}",
    get_all_admin: "org/{0}/list-admin",
    update_company_name: "org/update-name"
  },
  subscription: {
    get: "subscription/get",
    get_all: "subscription/list",
    countriesList: "subscription/countriesList",
    subscribe: "subscription",
    updateSubscription: "subscription/{0}",
    update_team: "subscription/{0}/update_team",
    update_billing_info: "subscription/{0}/billing-info",
    update_payment_data: "subscription/{0}/payment",
    update_plan: "subscription/{0}/plan",
    delete_payment_data: "subscription/delete_payment_data?id=",
    unsubscribe: "subscription/{0}",
    intent: "subscription/intent",
    listUser: "subscription/{0}/details",
    invoices: "subscription/{0}/invoices"
  },
  member: {
    otherPlatform: "org/users/otherPlatform"
  },
  standup: {
    list: "standup/list",
    standup: "standup",
    get: "standup/{0}",
    update: "standup/{0}",
    delete: "standup/{0}",
    answer: "standup/{0}/conversation",
    webReport: "standup/{0}/report?from={1}&to={2}&page={3}&user_id={4}",
    aiWebReport: "standup/{0}/summary?date={1}",
    downloadReport:
      "standup/{0}/download-report?from={1}&to={2}&output_format={3}&user_id={4}",
    standupConversation: "standup/{0}/conversations/today",
    users: "standup/{id}/users",
    members: "standup/{0}/members"
  },
  teams: {
    auth: "teams/authenticate?token="
  },
  platform: {
    channels: "platform/channels/list",
    channelsBigTeam: "platform/channels/big/list",
    channelUsers: "platform/channel/{0}/users/list?idOnly={1}",
    channelUsersById: "platform/channel/users/list",
    sharedUsers: "platform/users/shared/list",
    listUsers: "platform/users/list"
  },
  orgstat: {
    actualCount: "/orgstat/actual-count"
  }
}
