import { createSlice } from "@reduxjs/toolkit"
import { TYPES } from "src/utils/constants/types.constants"

const initialState: StateManage = {
  loading: false
}

const manageSlide = createSlice({
  name: TYPES.AUTH_TOAST,
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { changeLoading } = manageSlide.actions
export default manageSlide.reducer
