import { styled } from "@mui/system"
import { Link } from "react-router-dom"
import { BREAKPOINT, COLOR } from "src/utils/constants/styles.constant"

export const TopBar = styled("div")(({ theme, color }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: ` ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(
    3
  )} ${theme.spacing(6)}`,
  position: "sticky",
  top: 0,
  zIndex: 2,
  transition: "250ms",
  ...(!color && {
    "&.sticky": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderBottom: `0px solid ${COLOR.BACKGROUND}`
    }
  }),
  alignItems: "center",
  backgroundColor: color || COLOR.WHITE,
  "& .sidebar__menu": {
    width: 40,
    height: 40,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(BREAKPOINT.SM)]: {
      marginTop: theme.spacing(0.5)
    }
  },
  ...(!color && {
    borderBottom: `2px solid ${COLOR.BACKGROUND}`
  }),
  [theme.breakpoints.up(BREAKPOINT.LG)]: {
    "& .sidebar__menu": {
      display: "none"
    }
  },
  [theme.breakpoints.down(BREAKPOINT.MD)]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      2
    )} ${theme.spacing(2)}`,
    "& h6": {
      fontSize: 16
    }
  }
}))

export const TopBarTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(BREAKPOINT.SM)]: {
    "& h6": {
      alignItems: "center",
      fontSize: 20,
      display: "flex",
      height: "100%"
    },
    fontSize: 10
  }
}))
export const TopLineNav = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: COLOR.BLACK,
  "&:hover": {
    textDecoration: "underline"
  }
}))
