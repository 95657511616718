import React, { FC } from "react"
import { Route, Redirect } from "react-router-dom"
import { useAppSelector } from "src/hooks/redux"
import { PATH } from "src/utils/constants/path.constants"

export const AuthenticatedGuard: FC<React.ComponentProps<typeof Route>> =
  props => {
    const isAuthenticated = useAppSelector(state => state.app.isAuthenticated)

    return (
      <Route {...props}>
        {isAuthenticated ? props.children : <Redirect to={PATH.LOGIN} />}
      </Route>
    )
  }
