import React, { useEffect, Fragment } from "react"
import Routes from "src/routes/routes"
import "../assets/scss/index.scss"
import ReactGA, { event } from "react-ga"
import { analytics } from "src/utils/analytics/analytics.constant"
import Bugsnag from "@bugsnag/js"
import { useAppSelector } from "src/hooks/redux"
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: analytics.gtmId!
}
ReactGA.initialize(analytics.tracking_id!)
TagManager.initialize(tagManagerArgs)
function App() {
  const user = useAppSelector(state => state.user)
  useEffect(() => {
    if (user) {
      Bugsnag.setUser(user.me?.id, user.me?.email, user.me?.displayName)
      Bugsnag.addMetadata("company", {
        name: user.userOrg?.rawName!,
        orgId: user.userOrg?.id
      })
    }
  }, [user])
  return (
    <Fragment>
      <Routes />
    </Fragment>
  )
}

export default App
