import { createSlice } from "@reduxjs/toolkit"
import { authService } from "src/services/auth.service"
import { AppThunk } from "src/store/store"
import { TYPES } from "src/utils/constants/types.constants"

const initialState: StateApi = {
  loading: false
}

const authSlide = createSlice({
  name: TYPES.AUTH,
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
    },
    unLoading: state => {
      state.loading = false
    }
  }
})

export const { loading, unLoading } = authSlide.actions

export const callApiLogin =
  (authenticateReq): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loading())
    return authService.login(authenticateReq.code, authenticateReq.state)
  }

export const callApiInstall =
  (authenticateReq): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loading())
    return authService.install(authenticateReq.code, authenticateReq.state)
  }
export default authSlide.reducer
