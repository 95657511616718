import { Button, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { Fragment } from "react"
import Header from "src/components/Header/Header"
import { useHistory } from "react-router-dom"
import { PATH } from "src/utils/constants/path.constants"
const NotFound = () => {
  const history = useHistory()
  return (
    <Fragment>
      <Header topLine="Standup Alice" titleLine="404" />
      <Box sx={{ padding: 6 }}>
        <Typography>Sorry, the requested page was not found!</Typography>
        <Button
          onClick={() => history.push(PATH.DASHBOARD)}
          sx={{ padding: 2, mt: 3 }}
          variant="outlined"
        >
          Go to dashboard
        </Button>
      </Box>
    </Fragment>
  )
}

export default NotFound
