import { createSlice } from "@reduxjs/toolkit"
import { TYPES } from "src/utils/constants/types.constants"

const initialState: StateStandups = {
  standups: [],
  loadingSelectChannel: false,
  standupTemplate: undefined
}

const standupSlide = createSlice({
  name: TYPES.STANDUP,
  initialState,
  reducers: {
    updateStandups: (state, action) => {
      state.standups = action.payload
    },
    changeLoadingSelectChannel: (state, action) => {
      state.loadingSelectChannel = action.payload
    },
    updateStandupTemplate: (state, action) => {
      state.standupTemplate = action.payload
    }
  }
})

export const {
  updateStandups,
  changeLoadingSelectChannel,
  updateStandupTemplate
} = standupSlide.actions

export default standupSlide.reducer
