import { EApiErrorCode } from "../enums/error-code.enum"

export const ERROR_CODE = {
  ADMIN_REQUIRED: "ADMIN_REQUIRED",
  AUTH_AUTH_EXCEPTION: "AUTH_AUTH_EXCEPTION",
  AUTH_EMPTY_REDIRECT_URI: "AUTH_EMPTY_REDIRECT_URI",
  AUTH_INVALID_CODE: "AUTH_INVALID_CODE",
  AUTH_INVALID_TOKEN_TRY_AGAIN: "AUTH_INVALID_TOKEN_TRY_AGAIN",
  AUTH_JSON_EXCEPTION: "AUTH_JSON_EXCEPTION",
  AUTH_MS_TEAM_INVALID_TOKEN: "AUTH_MS_TEAM_INVALID_TOKEN",
  AUTH_ONETIME_INVALID_CODE: "AUTH_ONETIME_INVALID_CODE",
  AUTH_REFRESH_TOKEN_EXPIRED: "AUTH_REFRESH_TOKEN_EXPIRED",
  AUTH_UNAUTHORIZED: "AUTH_UNAUTHORIZED",
  CONTACT_US: "CONTACT_US",
  INPUT_NOT_READABLE: "INPUT_NOT_READABLE",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  NOT_IN_CHANNEL: "NOT_IN_CHANNEL",
  ORG_NOT_FOUND: "ORG_NOT_FOUND",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  REINSTALL_REQUIRED: "REINSTALL_REQUIRED",
  RESELECT_CHANNEL: "RESELECT_CHANNEL",
  RETRY_REQUIRED: "RETRY_REQUIRED",
  STANDUP_INVALID_STANDUP: "STANDUP_INVALID_STANDUP",
  STANDUP_NOT_FOUND: "STANDUP_NOT_FOUND",
  SUB_NOT_FOUND: "SUB_NOT_FOUND",
  TO_BE_DEFINED: "TO_BE_DEFINED",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN: "UNKNOWN",
  USER_NOT_FOUND: "USER_NOT_FOUND"
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}
export const ERROR_CODE_MESSAGE: EnumDictionary<EApiErrorCode, string> = {
  [EApiErrorCode.AUTH_AUTH_EXCEPTION]: "Please try again.",
  [EApiErrorCode.AUTH_EMPTY_REDIRECT_URI]: "Invalid redirect.",
  [EApiErrorCode.AUTH_INVALID_CODE]: "Code is empty. Please try again.",
  [EApiErrorCode.AUTH_INVALID_STATE]: "State is empty. Please try again.",
  [EApiErrorCode.AUTH_INVALID_TOKEN_TRY_AGAIN]: "",
  [EApiErrorCode.AUTH_JSON_EXCEPTION]: "",
  [EApiErrorCode.AUTH_MS_TEAM_INVALID_TOKEN]:
    "Please request another url to login.",
  [EApiErrorCode.AUTH_ONETIME_INVALID_CODE]:
    "Please request another url to login.",
  [EApiErrorCode.AUTH_REFRESH_TOKEN_EXPIRED]: "",
  [EApiErrorCode.AUTH_UNAUTHORIZED]:
    "An error occurs, please contact your System administrator to try again.",
  [EApiErrorCode.AUTH_USER_NOT_FOUND]: "User not found.",
  [EApiErrorCode.ORG_NOT_FOUND]: "Team not found.",
  [EApiErrorCode.REINSTALL_REQUIRED]: "Please re-install Standup Alice.",
  [EApiErrorCode.STANDUP_NOT_FOUND]: "Standup not found.",
  [EApiErrorCode.USER_NOT_FOUND]: "User not found.",
  [EApiErrorCode.STANDUP_INVALID_STANDUP]: "Invalid standup data.",
  [EApiErrorCode.TO_BE_DEFINED]: "",
  [EApiErrorCode.INTERNAL_ERROR]:
    "An internal error occured on our server, please try again later or contact Standup Alice support.",
  [EApiErrorCode.LOGIN_REQUIRED]: "Please login.",
  [EApiErrorCode.ADMIN_REQUIRED]: `Please contact your Administrator. You don't have have permission to do this action.`,
  [EApiErrorCode.RETRY_REQUIRED]: "Please retry.",
  [EApiErrorCode.PERMISSION_DENIED]:
    "Please contact your administrator, you do not have permission.",
  [EApiErrorCode.RESELECT_CHANNEL]: "Please re-select your channel.",
  [EApiErrorCode.NOT_IN_CHANNEL]:
    "There is an user who is not in this channel.",
  [EApiErrorCode.INPUT_NOT_READABLE]: "Please review your data.",
  [EApiErrorCode.UNKNOWN]: "Unknown error, please contact our support service.",
  [EApiErrorCode.CONTACT_US]: "Please contact our support service.",
  [EApiErrorCode.SUB_NOT_FOUND]: "Subscription not found.",
  [EApiErrorCode.UNAUTHORIZED]: "Please login."
}
