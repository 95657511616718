import React, { Fragment, useState, useEffect, ReactNode } from "react"
import { Typography, IconButton } from "@mui/material"
import { TopBar, TopBarTitle, TopLineNav } from "./Header.style"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import MenuIcon from "@mui/icons-material/Menu"
import { openSidebar } from "src/reducer/home.reducer"
import { Box } from "@mui/system"
import { useLocation } from "react-router-dom"
interface Props {
  topLine?: string
  topLine2?: string
  topLine3?: string
  titleLine?: string
  children?: ReactNode
  path?: string
  path2?: string
  path3?: string
  color?: string
}
const Header = (props: Props) => {
  const {
    topLine,
    titleLine,
    topLine2,
    topLine3,
    children,
    path,
    path2,
    color
  } = props
  const open = useAppSelector(state => state.home.openSidebar)
  const [scrolling, setScrolling] = useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { pathname } = location
  useEffect(() => {
    function onScroll(e) {
      return setScrolling(e.target.documentElement.scrollTop > 0)
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <Fragment>
      <TopBar color={color} className={scrolling ? "sticky" : ""}>
        <Box sx={{ display: "flex" }}>
          {!open && (
            <IconButton
              className="sidebar__menu"
              aria-label="menu"
              color="inherit"
              onClick={() => dispatch(openSidebar())}
            >
              <MenuIcon color="secondary" fontSize="medium" />
            </IconButton>
          )}
          <TopBarTitle>
            <Typography variant="body1">
              {topLine && topLine2 ? (
                <TopLineNav to={path || pathname}>{topLine}</TopLineNav>
              ) : (
                <TopLineNav
                  style={{ pointerEvents: "none" }}
                  to={path || pathname}
                >
                  {topLine}
                </TopLineNav>
              )}
              {topLine2 && " / "}
              {topLine2 && topLine3 ? (
                <TopLineNav to={path2 || pathname}>{topLine2}</TopLineNav>
              ) : (
                <TopLineNav
                  style={{ pointerEvents: "none" }}
                  to={path2 || pathname}
                >
                  {topLine2}
                </TopLineNav>
              )}
              {topLine3 && " / "}
              {topLine3 && (
                <TopLineNav style={{ pointerEvents: "none" }} to={pathname}>
                  {topLine3}
                </TopLineNav>
              )}
            </Typography>

            <Typography variant="h6">{titleLine}</Typography>
          </TopBarTitle>
        </Box>
        {children}
      </TopBar>
    </Fragment>
  )
}

export default Header
