import { createSlice } from "@reduxjs/toolkit"
import { TYPES } from "src/utils/constants/types.constants"

const initialState: StateHome = {
  openSidebar: true
}

const homeSlice = createSlice({
  name: TYPES.HOME,
  initialState,
  reducers: {
    openSidebar: state => {
      state.openSidebar = true
    },
    closeSidebar: state => {
      state.openSidebar = false
    }
  }
})

export const { openSidebar, closeSidebar } = homeSlice.actions
export default homeSlice.reducer
