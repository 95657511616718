import React, { lazy, Suspense } from "react"
import { AuthenticatedGuard } from "src/guards/AuthenticatedGuard"
import { PATH } from "src/utils/constants/path.constants"
import Loading from "src/components/Loading/Loading"
import { Switch } from "react-router-dom"
const Home = lazy(() => import("src/pages/Home/Home"))

export default function HomeRoutes() {
  return (
    <Switch>
      <AuthenticatedGuard path={PATH.HOME}>
        <Suspense fallback={<Loading />}>
          <Home />
        </Suspense>
      </AuthenticatedGuard>
    </Switch>
  )
}
