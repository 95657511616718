export const BREAKPOINT = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400
}

export const COLOR = {
  BLUE: "#0d6efd",
  WHITE: "#FFFFFF",
  DARK_BLUE: "rgba(var(--sk_highlight,18,100,163),1)",
  BLACK: "#000000",
  BLACK_OLIVE: "rgba(0, 0, 0, 0.87)",
  LIGHT_GREEN: "rgba(40, 167, 69, 0.07)",
  LIGHT_BLACK: "rgba(0, 0, 0, 0.87)",
  GREEN: "#28A745",
  PRIMARY: "#2D962D",
  SECONDARY: "#6C757D",
  LINE_COLOR: "rgba(0, 0, 0, 0.12)",
  BACKGROUND_CHANEL_BTN: "rgba(0, 0, 0, 0.08)",
  STANDUP_ITEM_HOVER: "#E5E5E5",
  COMMAND: "#E01E5A",
  SIDE_BAR: "#F6F6F6",
  DARK_RED: "#DC3545",
  NOTES: "rgba(0, 0, 0, 0.6)",
  BACKGROUND: "#F6F6F6",
  WARNING: "#FFC107",
  INFO: "#17A2B8",
  TEXT_SECONDARY: "rgba(0, 0, 0, 0.6)",
  MS_TEAM: "#6264A7",
  CISCO: "#D7F4FF",
  GOOGLE: "#1A73E8"
}
