import { Box, styled } from "@mui/system"

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  padding: 6,
  "& form": {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
}))
export const Img = styled("img")(({ theme }) => ({
  maxWidth: 496,
  maxHeight: 496,
  marginBottom: theme.spacing(2)
}))
