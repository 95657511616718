import { LinearProgress } from "@mui/material"
import React from "react"
import { useAppSelector } from "src/hooks/redux"

export default function Loading() {
  const loading = useAppSelector(state => state.auth.loading)
  return (
    <React.Fragment>
      <LinearProgress className={loading ? "load" : "un__load"} />
    </React.Fragment>
  )
}
