import React, { Fragment } from "react"
import { Route } from "react-router-dom"
import LoginRoutes from "./LoginRoutes"
import HomeRoutes from "./HomeRoutes"
import LoginByToken from "src/pages/Login/LoginByToken/LoginByToken"
import { PATH } from "src/utils/constants/path.constants"
import { useAppSelector } from "src/hooks/redux"
export default function Routes() {
  const isAuthenticated = useAppSelector(state => state.app.isAuthenticated)
  return (
    <Fragment>
      <LoginRoutes />
      <HomeRoutes />
      {!isAuthenticated && (
        <Route path={PATH.LOGIN_BY_TOKEN}>
          <LoginByToken />
        </Route>
      )}
    </Fragment>
  )
}
