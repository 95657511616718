import { API } from "src/utils/constants/api.constants"
import { api } from "../auth.interceptor"
import axios from "axios"
import { environment } from "../environments/environment.dev"
import { tokenService } from "./token.service"
import { pathHelpers } from "src/utils/helpers/path.helper"

class AuthService {
  install = (code: string, state: string): Promise<Res<AuthenticateRes>> => {
    const apiUrl = API.AUTH.INSTALL
    const redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI
    return api.post(apiUrl, {
      code,
      state,
      redirectUri: redirectUri
    })
  }

  login = (code: string, state: string): Promise<Res<AuthenticateRes>> => {
    const apiUrl = API.AUTH.LOGIN
    let redirectUri = ""
    if (state === "webex_login") {
      redirectUri = process.env.REACT_APP_WEBEX_REDIRECT_URI!
    } else {
      redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI!
    }
    return api.post(apiUrl, {
      code,
      state,
      redirectUri: redirectUri
    })
  }

  refreshToken = () => {
    const apiUrl = API.AUTH.REFRESH_TOKEN
    const refreshTokenApi = axios.create({
      baseURL: environment.api.base,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenService.getLocalRefreshToken()}`
      }
    })
    return refreshTokenApi.get(apiUrl)
  }

  signOut = (): Promise<Res<any>> => {
    const apiUrl = API.AUTH.SIGN_OUT
    return api.get(apiUrl)
  }

  loginByToken = (code: string): Promise<Res<AuthenticateRes>> => {
    const apiUrl = pathHelpers.format(API.AUTH.LOGIN_BY_TOKEN, code)
    return api.get(apiUrl)
  }

  updatePaymentLogin = (code: string) : Promise<Res<AuthenticateRes>> => {
    const apiUrl = pathHelpers.format(API.AUTH.UPDATE_PAYMENT_LOGIN, code)
    return api.get(apiUrl)
  }
}
export const authService = new AuthService()
