export const analytics = {
  tracking_id: process.env.REACT_APP_TRACKING_ID,
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  event_title: {
    create_standup: "Click the button create standup",
    build_your_own: "Click the button build_your_own",
    save_button: "Click the button save standup",
    update_timezone: "Click the button update timezone",
    update_reminder_settings:
      "Click the button update Standup reminder settings",
    download_data_my_profile: "Click the button download data my profile",
    edit_settings: "Click the button edit settings",
    duplicate_standup: "Click the button duplicate standup",
    delete_standup: "Click the button delete standup",
    download_report_json: "Click download report JSON",
    download_report_csv: "Click download report CSV",
    more_report: "Click see more report",
    refresh_channel: "Click the button refresh channel",
    add_participants_from_channel: "Click add all user from channel",
    cancel_confirm_delete_standup: "Click cancel confirm delete",
    ok_confirm_delete_standup: "Click ok confirm delete",
    use_template: "Click use template ",
    update_admin: "Update admin",
    delete_admin: "Delete admin",
    update_team_name: "Update team name",
    update_state: "Update user state"
  }
}
