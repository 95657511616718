import React, { useEffect } from "react"
import qs from "qs"
import {
  callApiInstall,
  callApiLogin,
  loading,
  unLoading
} from "../reducer/auth.reducer"
import { tokenService } from "src/services/token.service"
import { SeverityEnum } from "src/utils/enums/toast.enum"
import { messages } from "src/utils/constants/messages"
import { useAppDispatch } from "src/hooks/redux"
import { PATH } from "src/utils/constants/path.constants"
import { createToast } from "src/reducer/toastAuth.reducer"
import { loginSuccess } from "src/reducer/app.reducer"
enum stateAuth {
  LOGIN = "authv2",
  INSTALL = "installv2"
}
interface Props {
  location: Location
}

const handleToast = (dispatch, messages, severity) => {
  const toast: ToastParams = {
    messages,
    severity
  }
  dispatch(createToast(toast))
}
const callLogin = (dispatch, authenticateReq, props) => {
  dispatch(callApiLogin(authenticateReq))
    .then((res: Res<AuthenticateRes>) => {
      if (res && !res.data.ok) {
        dispatch(unLoading())
        handleToast(dispatch, messages.auth.error, SeverityEnum.ERROR)
        props.history.push(PATH.LOGIN)
      } else {
        const authenticateRes = res.data.data
        dispatch(unLoading())
        tokenService.updateLocalAccessToken(authenticateRes.id_token)
        tokenService.updateRefreshToken(authenticateRes.refresh_token)
        tokenService.updateUserId(`${authenticateRes.user_id}`)
        dispatch(loginSuccess())
        handleToast(dispatch, messages.auth.success, SeverityEnum.SUCCESS)
        props.history.push(PATH.DASHBOARD)
      }
    })
    .catch(err => {
      dispatch(unLoading())
      handleToast(dispatch, messages.auth.error, SeverityEnum.ERROR)
      props.history.push(PATH.LOGIN)
    })
}
const callInstall = (dispatch, authenticateReq, props) => {
  dispatch(callApiInstall(authenticateReq))
    .then((res: Res<AuthenticateRes>) => {
      if (res && !res.data.ok) {
        dispatch(unLoading())
        handleToast(dispatch, messages.auth.error, SeverityEnum.ERROR)
        props.history.push(PATH.LOGIN)
      } else {
        const authenticateRes = res.data.data
        dispatch(unLoading())
        tokenService.updateLocalAccessToken(authenticateRes.id_token)
        tokenService.updateRefreshToken(authenticateRes.refresh_token)
        tokenService.updateUserId(`${authenticateRes.user_id}`)
        handleToast(dispatch, messages.auth.success, SeverityEnum.SUCCESS)
        dispatch(loginSuccess())
        props.history.push(PATH.DASHBOARD)
      }
    })
    .catch(err => {
      dispatch(unLoading())
      handleToast(dispatch, messages.auth.error, SeverityEnum.ERROR)
      props.history.push(PATH.LOGIN)
    })
}
const AuthResolver = (props: Props) => {
  const dispatch = useAppDispatch()
  const authenticateReq = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    if (!!authenticateReq.state)
      if (authenticateReq.state === stateAuth.INSTALL) {
        dispatch(loading())
        callInstall(dispatch, authenticateReq, props)
      } else {
        dispatch(loading())
        callLogin(dispatch, authenticateReq, props)
      }
  }, [])

  return <></>
}

export default AuthResolver
