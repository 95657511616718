import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/store/store"
import { TYPES } from "src/utils/constants/types.constants"
import { SeverityEnum } from "src/utils/enums/toast.enum"

const initialState: ToastState = {
  open: false,
  messages: "",
  severity: SeverityEnum.INFO
}

const toastAuthSlide = createSlice({
  name: TYPES.AUTH_TOAST,
  initialState,
  reducers: {
    createToast: (state, action) => {
      const toastParams: ToastParams = action.payload
      state.messages = toastParams.messages
      state.severity = toastParams.severity
      state.open = true
    },
    closeToast: state => {
      state.open = false
    }
  }
})

export const messagesToastAuth = (state: RootState) => state.authToast.messages
export const { createToast, closeToast } = toastAuthSlide.actions
export default toastAuthSlide.reducer
