// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  slack: {
    client_id: "4878724429.147565410354",
    redirect_uri: "https://localhost:4200/auth",

    // client_id: '4878724429.149217102503',
    // redirect_uri: 'https://localhost:4200/auth',

    scope_auth: "identity.basic,identity.team,identity.avatar",
    scope_install:
      "channels:read,chat:write,commands,groups:read,im:history,im:read,im:write,reactions:write,users:read,users:read.email"
  },
  discord: {
    client_id: "759013281665908756", // dev
    redirect_uri: "https://localhost:4200/auth",
    enabled: true,
    login_scope: "identify/email",
    install_scope: "identify/email/bot",
    install_permission: 166976
  },
  msteams: {
    client_id: "79def875-6a91-40b1-9b38-fac950677b02",
    redirect_uri: "https://localhost:4200/",
    postLogoutRedirectUri: "https://localhost:4200/",
    navigateToLoginRequestUrl: true,
    scope: "user.read"
  },
  webex: {
    client_id:
      "C35ca2194130c3f87ef28c5b5ee5afd36499373fd92ee355c9c23447b6d052225",
    redirect_uri: "https://localhost:4200/auth",
    enabled: true
  },
  api: {
    base: "https://alice-backend-staging-wbryv4dggq-uc.a.run.app/api/v2/"
  },
  stripe: {
    client_id: "pk_test_51MiswMBORK64mIBCmJkol0HfDJvOuqTzypga4YIPM6Po6KIPhSoG6G1QIbsZUhXfm6uWPFa1Zn08eHB9zFRDV17S00qHan0mAS"
  },
  bugsnag: "7f7048765e2acfc8c6ca0f442b1398cf",
  version: "V1.0"
}
