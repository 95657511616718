import { createSlice } from "@reduxjs/toolkit"
import { TYPES } from "src/utils/constants/types.constants"
import { TabManageEnum } from "src/utils/enums/tab-mange.enum"

const initialState: StateNewStandup = {
  loadingSelectUser: false,
  tab: TabManageEnum.BASIC
}

const newStandupSlide = createSlice({
  name: TYPES.NEW_STANDUP,
  initialState,
  reducers: {
    changeLoad: (state, action) => {
      state.loadingSelectUser = action.payload
    },
    changeTab: (state, action) => {
      state.tab = action.payload
    }
  }
})

export const { changeLoad, changeTab } = newStandupSlide.actions

export default newStandupSlide.reducer
