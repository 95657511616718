export enum EApiErrorCode {
  INTERNAL_ERROR = "INTERNAL_ERROR",
  LOGIN_REQUIRED = "LOGIN_REQUIRED",
  USER_NOT_FOUND = "USER_NOT_FOUND ",
  STANDUP_NOT_FOUND = "STANDUP_NOT_FOUND",
  ORG_NOT_FOUND = "ORG_NOT_FOUND",
  REINSTALL_REQUIRED = "REINSTALL_REQUIRED",

  AUTH_INVALID_CODE = "AUTH_INVALID_CODE",
  AUTH_INVALID_STATE = "AUTH_INVALID_STATE",
  AUTH_AUTH_EXCEPTION = "AUTH_AUTH_EXCEPTION",
  AUTH_JSON_EXCEPTION = "AUTH_JSON_EXCEPTION",
  AUTH_EMPTY_REDIRECT_URI = "AUTH_EMPTY_REDIRECT_URI",

  AUTH_UNAUTHORIZED = "AUTH_UNAUTHORIZED",
  AUTH_ONETIME_INVALID_CODE = "AUTH_ONETIME_INVALID_CODE",

  AUTH_USER_NOT_FOUND = "AUTH_USER_NOT_FOUND",
  AUTH_INVALID_TOKEN_TRY_AGAIN = "AUTH_INVALID_TOKEN_TRY_AGAIN",
  AUTH_REFRESH_TOKEN_EXPIRED = "AUTH_REFRESH_TOKEN_EXPIRED",

  AUTH_MS_TEAM_INVALID_TOKEN = "AUTH_MS_TEAM_INVALID_TOKEN",

  STANDUP_INVALID_STANDUP = "STANDUP_INVALID_STANDUP",

  TO_BE_DEFINED = "TO_BE_DEFINED",

  ADMIN_REQUIRED = "ADMIN_REQUIRED",

  UNAUTHORIZED = "UNAUTHORIZED",
  SUB_NOT_FOUND = "SUB_NOT_FOUND",
  RETRY_REQUIRED = "RETRY_REQUIRED",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  RESELECT_CHANNEL = "RESELECT_CHANNEL",
  NOT_IN_CHANNEL = "NOT_IN_CHANNEL",
  CONTACT_US = "CONTACT_US",
  INPUT_NOT_READABLE = "INPUT_NOT_READABLE",
  UNKNOWN = "UNKNOWN"
}
