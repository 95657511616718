import { STORAGE } from "src/utils/constants/storage.constant"

class TokenService {
  getLocalRefreshToken = () => {
    return localStorage.getItem(STORAGE.REFRESH_TOKEN)
  }

  getLocalAccessToken = () => {
    return localStorage.getItem(STORAGE.ACCESS_TOKEN_ITEM)
  }

  updateLocalAccessToken = (token: string) => {
    localStorage.setItem(STORAGE.ACCESS_TOKEN_ITEM, token)
  }

  updateRefreshToken = (refresh: string) => {
    localStorage.setItem(STORAGE.REFRESH_TOKEN, refresh)
  }

  getUserId = () => {
    return localStorage.getItem(STORAGE.USER)
  }

  updateUserId = (userId: string) => {
    return localStorage.setItem(STORAGE.USER, userId)
  }

  removeUser = () => {
    localStorage.removeItem(STORAGE.USER)
  }
}
export const tokenService = new TokenService()
