import { api } from "src/auth.interceptor"
import { API } from "src/utils/constants/api.constants"
import { pathHelpers } from "src/utils/helpers/path.helper"

class SubscriptionService {
  getSubscriptions = (): Promise<Res<SubscriptionDataRes>> => {
    const apiUrl = API.subscription.get_all
    return api.get(apiUrl)
  }

  getSubscriptionUsers = (orgId: number): Promise<Res<SubscriptionUserRes>> => {
    const apiUrl = pathHelpers.format(API.subscription.listUser, orgId)
    return api.get(apiUrl)
  }

  updateSubscription = (
    plan: SubscriptionReq
  ): Promise<Res<SubscriptionReq>> => {
    const apiUrl = pathHelpers.format(API.subscription.update_plan, plan.id)
    return api.put(apiUrl, plan)
  }

  updatePayment = (
    { paymentMethod, name },
    id: number
  ): Promise<Res<SubscriptionReq>> => {
    const apiUrl = pathHelpers.format(API.subscription.update_payment_data, id)
    return api.put(apiUrl, { paymentMethod: paymentMethod, name: name })
  }

  updateBillingInfo = (subId, data): Promise<Res<SubscriptionReq>> => {
    const apiUrl = pathHelpers.format(
      API.subscription.update_billing_info,
      subId
    )
    return api.put(apiUrl, data)
  }

  subscribe3DSecured = (
    subscription: SubscriptionReq
  ): Promise<Res<SubscriptionReq>> => {
    const apiUrl = API.subscription.subscribe
    return api.post(apiUrl, subscription)
  }

  setupIntent = (): Promise<Res<IntentDto>> => {
    const apiUrl = API.subscription.intent
    return api.post(apiUrl)
  }

  getCountries = (): Promise<Res<any[]>> => {
    const apiUrl = API.subscription.countriesList
    return api.get(apiUrl)
  }

  deleteSub = (subId: any): Promise<Res<any[]>> => {
    const apiUrl = pathHelpers.format(API.subscription.unsubscribe, subId)
    return api.delete(apiUrl)
  }

  getInvoiceList = (
    subId: any,
    nextPage?: string
  ): Promise<Res<InvoicesReq>> => {
    let apiUrl = pathHelpers.format(API.subscription.invoices, subId)
    apiUrl += nextPage
    return api.get(apiUrl)
  }
}

export const subscriptionService = new SubscriptionService()
