import React, { useEffect } from "react"
import emptyStandupsImg from "src/assets/image/empty-standups.png"
import { messages } from "src/utils/constants/messages"
import { Container, Img } from "./LoginByToken.style"
import qs from "qs"
import { authService } from "src/services/auth.service"
import { toastHelpers } from "src/utils/helpers/toast.helpers"
import { useAppDispatch } from "src/hooks/redux"
import { SeverityEnum } from "src/utils/enums/toast.enum"
import { tokenService } from "src/services/token.service"
import { PATH } from "src/utils/constants/path.constants"
import { loading, unLoading } from "src/reducer/auth.reducer"
import { useHistory } from "react-router-dom"
import { loginSuccess } from "src/reducer/app.reducer"
import { callApiGetUser } from "src/reducer/user.reducer"
import { subscriptionService } from "src/services/subscription.service"
const { search } = window.location
const LoginByToken = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  useEffect(() => {
    const code = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    if (code.code && typeof code.code === "string") {
      const redirectUrl = (code.redirectUrl as string) || PATH.DASHBOARD
      callApiLogin(code.code, redirectUrl)
    }
  }, [])
  const loginFunction = (redirectUrl, code) => {
    if(redirectUrl === "/checkBilling") {
      return authService.updatePaymentLogin(code)
    } else {
      return authService.loginByToken(code)
    }
  }
  const callApiLogin = (code: string, redirectUrl: string) => {
    localStorage.clear()
    dispatch(loading())
    loginFunction(redirectUrl, code)
      .then(async res => {
        dispatch(unLoading())
        const authenticateRes = res.data.data
        tokenService.updateLocalAccessToken(authenticateRes.id_token)
        tokenService.updateRefreshToken(authenticateRes.refresh_token)
        tokenService.updateUserId(`${authenticateRes.user_id}`)
        if(redirectUrl !== "/checkBilling"){
          toastHelpers.handleToast(
              dispatch,
              messages.auth.success,
              SeverityEnum.SUCCESS
            )
        }
        dispatch(loginSuccess())
        dispatch(callApiGetUser())
        if(redirectUrl === "/checkBilling") {
          subscriptionService.getSubscriptions()
          .then(result => {
            if(result.data.data.subscriptions && result.data.data.subscriptions[0] && result.data.data.subscriptions[0].state === "active" && result.data.data.subscriptions[0].stripeCustomer && result.data.data.subscriptions[0].stripeCustomer.hasPaymentData == true){
              toastHelpers.handleToast(
                dispatch,
                messages.auth.payment_update_success,
                SeverityEnum.SUCCESS
              )
            }
          })
          .catch(err => {
            dispatch(unLoading())
            toastHelpers.handleToast(
              dispatch,
              messages.subscription.error_get_subscription,
              SeverityEnum.ERROR
            )
          })
          redirectUrl = "/billing"
        }
        history.push(redirectUrl)
      })
      .catch(err => {
        dispatch(unLoading())
        toastHelpers.handleToast(
          dispatch,
          messages.auth.error,
          SeverityEnum.ERROR
        )
      })
  }
  return (
    <Container>
      <Img src={emptyStandupsImg} alt="" />
    </Container>
  )
}

export default LoginByToken
