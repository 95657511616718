class PathHelpers {
  format(chars: string, ...args): string {
    let result = chars
    for (const k in args) {
      result = result.replace(new RegExp("\\{" + k + "\\}", "g"), args[k])
    }
    return result
  }
}
export const pathHelpers = new PathHelpers()
